import "./App.css";
import Routing from "./Routing";
import NavMenu from "./NavMenu";
import { CpoProductsProvider } from "./contexts/CpoProductsContext";

function App() {
  return (
    <div className="App">
      <CpoProductsProvider>
        <NavMenu />
        <Routing />
      </CpoProductsProvider>
    </div>
  );
}

export default App;
