import React from "react";
import { Container, Segment, Tab } from "semantic-ui-react";
import "./index.css";
import CreateCustomerTab from "./CreateCustomerTab";

function CreateCustomer(props) {
  const panes = [
    {
      menuItem: "Create Customer",
      render: () => (
        <Tab.Pane attached={false}>
          <CreateCustomerTab method={"createCustomer"} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Container>
      <Segment raised>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      </Segment>
    </Container>
  );
}

export default CreateCustomer;
