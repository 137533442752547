import React, { useState, useEffect, Fragment } from "react";
import Papa from "papaparse";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { postWhitelist, getProductgroups, postRfid } from "../../helper/api";
import { Form, Message, Modal, Icon, Header, Popup } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import encrypt, { decrypt } from "../../helper/encryption";
import constants from "../../helper/constants.json";
import { Cookies } from "react-cookie";

function WhitelistTab(props) {
  let navigate = useNavigate();
  const cookies = new Cookies();
  const [activeItem, setActiveItem] = useState("");
  const [customerResult, setCustomerResult] = useState([]);
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [message, setMessage] = useState("");
  const [messageRfid, setMessageRfid] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [notInList, setNotInList] = useState([]);
  const [unabletoProcessRfid, setUnabletoProcessRfid] = useState([]);
  const [getselectedGroup, setGetSelectedGroup] = useState(
    cookies.get(encrypt(constants.groupCookie)) !== undefined &&
      decrypt(cookies.get(encrypt(constants.groupCookie)))
        .toString()
        .trim()
  );
  const csvData =
    activeItem === "Customers"
      ? [["email", "purpose (i.e. Operations)"]]
      : [["email", "rfid(e.g. 123C77D4)", "purpose (i.e. Operations)"]];

  const isSuperAdmin =
    cookies.get(encrypt(constants.rolesCookie)) !== undefined &&
    decrypt(cookies.get(encrypt(constants.rolesCookie)))
      .toString()
      .includes("Super Admin");

  const handleChange = (e, { value }) => setActiveItem(value);

  const handleCsvChange = (event) => {
    // console.log(event.target.files[0]);
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // console.log(results.data);
        setCustomerResult(results.data);
        setEnableSubmit(false);
      },
    });
  };

  const handleSubmit = () => {
    console.log({ data: customerResult, group: getselectedGroup });
    setIsLoading(true);

    if (activeItem === "Customers") {
      props
        .postWhitelist(props.method, {
          data: customerResult,
          group: getselectedGroup,
        })
        .then(function (response) {
          if (response.data.respcode === 200) {
            setIsLoading(false);
            console.log(response.data);

            if (response.data.notWhitelisted.length !== 0) {
              setDisplayWarning(true);
              setMessage("The following email not whitelisted");
              setNotInList(response.data.notWhitelisted);
            } else {
              setDisplaySuccess(true);
              setMessage(response.data.description);
            }
          } else {
            setIsLoading(false);
            setDisplayError(true);
            setMessage("Error: Unable to whitelist");
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status === 401) {
            setOpen(true);
          }
        });
    } else {
      props
        .postRfid(props.method, {
          data: customerResult,
          group: getselectedGroup,
        })
        .then(function (response) {
          if (response.data.respcode === 200) {
            setIsLoading(false);
            console.log(response.data);

            if (response.data.notWhitelisted.length !== 0) {
              setDisplayWarning(true);
              setMessage("The following email not whitelisted");
              setNotInList(response.data.notWhitelisted);
            } else if (response.data.failedRfidList.length !== 0) {
              setDisplayWarning(true);
              setMessageRfid(
                "The following RFID failed to be added on the following emails"
              );
              var errorList = response.data.failedRfidList.map(function (el) {
                return "Email: " + el.email + ", Error Message: " + el.message;
              });
              setUnabletoProcessRfid(errorList);
            } else {
              setDisplaySuccess(true);
              setMessageRfid(response.data.description);
            }
          } else {
            setIsLoading(false);
            setDisplayError(true);
            setMessage("Error: Unable to whitelist");
          }
        })
        .catch(function (error) {
          console.log(error.response);
          if (error.response.status === 401) {
            setOpen(true);
          }
        });
    }

    // props
    //   .postWhitelist(props.method, {
    //     data: customerResult,
    //     group: getselectedGroup,
    //   })
    //   .then(function (response) {
    //     console.log(response.data);
    //     if (response.data.respcode === 1200) {
    //       setDisplaySuccess(true);
    //       setMessage(response.data.message);
    //       setMessageRfid(response.data.messageRfid);
    //       setNotInList(response.data.notInList);
    //       var errorList = response.data.unabletoProcessRfid.map(function (el) {
    //         return "Email: " + el.email + ", Error Message: " + el.errors;
    //       });
    //       setUnabletoProcessRfid(errorList);
    //       response.data.notInList.length !== 0
    //         ? setDisplayWarning(true)
    //         : setDisplayWarning(false);
    //       response.data.unabletoProcessRfid.length !== 0
    //         ? setDisplayWarning(true)
    //         : setDisplayWarning(false);
    //       setIsLoading(false);
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error.response);
    //     if (error.response.status === 401) {
    //       setOpen(true);
    //     }
    //   });
  };

  //Toggle messages to disappear after 3 seconds
  useEffect(() => {
    if (displaySuccess) {
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setDisplaySuccess(!displaySuccess);
      }, 3000);

      return () => {
        clearTimeout(timeId);
      };
    }

    if (open) {
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        navigate("/");
      }, 2000);

      return () => {
        clearTimeout(timeId);
      };
    }
  });

  function MessageHandler() {
    if (activeItem === "Customers") {
      if (notInList.length !== 0) {
        return <Message warning header={message} list={notInList} />;
      } else {
        return displaySuccess ? (
          <Message success header="Form Submitted" content={message} />
        ) : (
          <Message error header="Form Submitted" content={message} />
        );
      }
    } else if (activeItem === "RFID") {
      if (unabletoProcessRfid.length !== 0) {
        return (
          <Message warning header={messageRfid} list={unabletoProcessRfid} />
        );
      } else {
        return displaySuccess ? (
          <Message success header="Form Submitted" content={messageRfid} />
        ) : (
          <Message error header="Form Submitted" content={messageRfid} />
        );
      }
    }
  }

  return (
    <Fragment>
      <Form
        onSubmit={handleSubmit}
        success={displaySuccess}
        warning={displayWarning}
        error={displayError}
        loading={loading}
      >
        <Form.Field
          required
          className="radio-boxes"
          style={{ paddingTop: "5px" }}
        >
          <label>Choose from the following:</label>
          <Form.Group style={{ paddingLeft: "10px" }}>
            <Form.Radio
              label={props.method + " Customers"}
              value="Customers"
              checked={activeItem === "Customers"}
              onChange={handleChange}
            />
            <Form.Radio
              label={props.method + " RFID"}
              value="RFID"
              checked={activeItem === "RFID"}
              onChange={handleChange}
            />
            {/* <Form.Radio
              label="All"
              value="Combined"
              checked={activeItem === "Combined"}
              onChange={handleChange}
            /> */}
          </Form.Group>
          {activeItem && (
            <Popup
              content={`Download ${activeItem} Template`}
              trigger={
                <CSVLink
                  data={csvData}
                  filename={`template-${activeItem}.csv`}
                  style={{ paddingLeft: "20px", marginTop: "-1px" }}
                >
                  <Icon size="large" name="download" />
                </CSVLink>
              }
            />
          )}
        </Form.Field>
        <Form.Group>
          <Form.Input
            inline
            className="upload-csv"
            label="Upload CSV"
            type="file"
            accept=".csv"
            onChange={handleCsvChange}
            required
            // error={{
            //   content: "Only Csv files are allowed",
            //   pointing: "left",
            // }}
          />
        </Form.Group>

        {/* {notInList.length !== 0 && activeItem === "Customers" ? (
          <Message warning header={message} list={notInList} />
        ) : (
          <Message success header="Form Submitted" content={message} />
        )}

        {activeItem !== "Customers" && unabletoProcessRfid.length !== 0 ? (
          <Message warning header={messageRfid} list={unabletoProcessRfid} />
        ) : (
          <Message success header="Form Submitted" content={messageRfid} />
        )} */}

        <MessageHandler />

        <Form.Button disabled={enableSubmit}>Submit</Form.Button>
      </Form>
      <Modal
        basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="small"
      >
        <Header icon>
          <Icon name="sign-out" />
          401 Unauthorized Access, redirecting you to login page
        </Header>
      </Modal>
    </Fragment>
  );
}

export default connect(null, { postWhitelist, getProductgroups, postRfid })(
  WhitelistTab
);
