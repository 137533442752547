import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../Login";
import Whitelist from "../Whitelist";
import DashboardRoute from "../contexts/DashboardRoute";
import AdminRoute from "../contexts/AdminRoute";
import ResetPassword from "../ResetPassword";
import Admin from "../Admin";
import Home from "../Home";
import CreateCustomer from "../CreateCustomer";

export default function Routing() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route exact path="/" element={<DashboardRoute />}>
        <Route exact path="/home" element={<Home />} />
      </Route>
      <Route exact path="/" element={<DashboardRoute />}>
        <Route exact path="/whitelist" element={<Whitelist />} />
      </Route>
      <Route exact path="/" element={<AdminRoute />}>
        <Route exact path="/admin" element={<Admin />} />
      </Route>
      <Route exact path="/resetpassword" element={<ResetPassword />} />
      <Route exact path="/createcustomer" element={<CreateCustomer />} />
    </Routes>
  );
}
